import hljs from './Libs/hljs/es/highlight.js';

const languages = {
    bash: () => import('./Libs/hljs/es/languages/bash.js'),
    css: () => import('./Libs/hljs/es/languages/css.js'),
    java: () => import('./Libs/hljs/es/languages/java.js'),
    javascript: () => import('./Libs/hljs/es/languages/javascript.js'),
    json: () => import('./Libs/hljs/es/languages/json.js'),
    php: () => import('./Libs/hljs/es/languages/php.js'),
    plaintext: () => import('./Libs/hljs/es/languages/plaintext.js'),
    shell: () => import('./Libs/hljs/es/languages/shell.js'),
    sql: () => import('./Libs/hljs/es/languages/sql.js'),
    typescript: () => import('./Libs/hljs/es/languages/typescript.js'),
    xml: () => import('./Libs/hljs/es/languages/xml.js'),
    yaml: () => import('./Libs/hljs/es/languages/yaml.js'),
};

export async function initHljs() {
    const codeBlocks = document.querySelectorAll('pre code');
    for (const codeBlock of codeBlocks) {
        const language = codeBlock.className.replace('language-', '');
        languages[language]().then(module => {
            hljs.registerLanguage(language, module.default);
            hljs.highlightElement(codeBlock);
        });
    }
}